import { defineMessages } from 'react-intl';

const MESSAGES = defineMessages({
    actions: {
        defaultMessage: 'Actions',
        id: 'main.label.actions',
    },
    edit: {
        defaultMessage: 'Edit',
        id: 'main.label.edit',
    },
    delete: {
        defaultMessage: 'Delete',
        id: 'main.label.delete',
    },
    see: {
        defaultMessage: 'See',
        id: 'main.label.see',
    },
    result: {
        defaultMessage: 'Result',
        id: 'main.label.result',
    },
    positive: {
        defaultMessage: 'Positive',
        id: 'main.label.positive',
    },
    negative: {
        defaultMessage: 'Negative',
        id: 'main.label.negative',
    },
    home: {
        defaultMessage: 'Home',
        id: 'trypelim.navigation.home',
    },
    stats: {
        defaultMessage: 'Stats',
        id: 'trypelim.navigation.stats',
    },
    stats_epidemiology: {
        defaultMessage: 'Epidemiology',
        id: 'trypelim.navigation.stats.epidemiology',
    },
    stats_data_monitoring: {
        defaultMessage: 'Data monitoring',
        id: 'trypelim.navigation.stats_data_monitoring',
    },
    stats_reports: {
        defaultMessage: 'Reports',
        id: 'trypelim.navigation.stats_reports',
    },
    datas: {
        defaultMessage: 'Datas',
        id: 'trypelim.navigation.datas',
    },
    datas_tests: {
        defaultMessage: 'Tests',
        id: 'trypelim.navigation.datas.tests',
    },
    datas_register: {
        defaultMessage: 'register',
        id: 'trypelim.navigation.datas.register',
    },
    datas_duplicates: {
        defaultMessage: 'Duplicates',
        id: 'trypelim.navigation.datas.duplicates',
    },
    management: {
        defaultMessage: 'Management',
        id: 'trypelim.navigation.management',
    },
    management_devices: {
        defaultMessage: 'Devices',
        id: 'trypelim.navigation.management.devices',
    },
    management_teams: {
        defaultMessage: 'Teams',
        id: 'trypelim.navigation.management.teams',
    },
    management_villages: {
        defaultMessage: 'Villages',
        id: 'trypelim.navigation.management.villages',
    },
    management_coordinations: {
        defaultMessage: 'Coordinations',
        id: 'trypelim.navigation.management.coordinations',
    },
    management_health_structures: {
        defaultMessage: 'Health structures',
        id: 'trypelim.navigation.management.healthStructures',
    },
    management_notifications: {
        defaultMessage: 'Notifications',
        id: 'trypelim.navigation.management.notifications',
    },
    plannings: {
        defaultMessage: 'Plannings',
        id: 'trypelim.navigation.plannings',
    },
    plannings_workzones: {
        defaultMessage: 'Plannings',
        id: 'trypelim.navigation.plannings.workzones',
    },
    plannings_macro: {
        defaultMessage: 'Macro',
        id: 'trypelim.navigation.plannings.macro',
    },
    plannings_micro: {
        defaultMessage: 'Micro',
        id: 'trypelim.navigation.plannings.micro',
    },
    plannings_routes: {
        defaultMessage: 'Routes',
        id: 'trypelim.navigation.plannings.routes',
    },
    vector_control: {
        defaultMessage: 'Vector control',
        id: 'trypelim.navigation.vector_control',
    },
    vector_control_management: {
        defaultMessage: 'Management',
        id: 'trypelim.navigation.vector_control.management',
    },
    vector_control_sync: {
        defaultMessage: 'Sync',
        id: 'trypelim.navigation.vector_control.sync',
    },
    vector_control_upload: {
        defaultMessage: 'Upload',
        id: 'trypelim.navigation.vector_control.upload',
    },
    lab: {
        defaultMessage: 'Lab',
        id: 'trypelim.navigation.lab',
    },
    lab_upload: {
        defaultMessage: 'Upload',
        id: 'trypelim.navigation.lab.upload',
    },
    lab_import_logs: {
        defaultMessage: 'Import logs',
        id: 'trypelim.navigation.lab.import_logs',
    },
    quality_control: {
        defaultMessage: 'Quality control',
        id: 'trypelim.navigation.quality_control',
    },
    quality_control_control: {
        defaultMessage: 'Dashboard',
        id: 'trypelim.navigation.quality_control.control',
    },
    quality_control_monitoring: {
        defaultMessage: 'Monitoring',
        id: 'trypelim.navigation.quality_control.monitoring',
    },
    new_quality_control: {
        defaultMessage: 'Quality control [NEW]',
        id: 'trypelim.navigation.new_quality_control',
    },
    search: {
        defaultMessage: 'Search',
        id: 'iaso.search',
    },
    download_file: {
        defaultMessage: 'Download uploaded file',
        id: 'trypelim.navigation.lab.download_file',
    },
});

export default MESSAGES;
