/*****************************************************************************/
// Extracted from config.js to be able to import separately for loading the
// Iaso sidebar in the Trypelim frontend. This avoids loading other loaded
// modules from config.js that gave many errors.
/*****************************************************************************/
import React from 'react';

import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import BiotechIcon from '@mui/icons-material/Biotech';
// import DonutLargeOutlinedIcon from '@mui/icons-material/DonutLargeOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FormatListBulleted from '@mui/icons-material/FormatListBulleted';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import HolidayVillageOutlinedIcon from '@mui/icons-material/HolidayVillageOutlined';
import ImportantDevicesRoundedIcon from '@mui/icons-material/ImportantDevicesRounded';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import NotesOutlinedIcon from '@mui/icons-material/NotesOutlined';
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import PolicyIcon from '@mui/icons-material/Policy';
import PolylineOutlinedIcon from '@mui/icons-material/PolylineOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined';
import StorageIcon from '@mui/icons-material/Storage';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import SyncAltOutlinedIcon from '@mui/icons-material/SyncAltOutlined';
import TabletAndroidOutlinedIcon from '@mui/icons-material/TabletAndroidOutlined';
import VaccinesOutlinedIcon from '@mui/icons-material/VaccinesOutlined';

import MESSAGES from './utils/constants/messages.js';

export const menu = [
    {
        label: MESSAGES.stats,
        key: 'old-trypelim/stats',
        permissions: ['iaso_trypelim_stats_reports'],
        icon: props => <InsightsOutlinedIcon {...props} />,
        subMenu: [
            // {
            //     label: MESSAGES.stats_epidemiology,
            //     key: 'epidemiology',
            //     permissions: ['iaso_trypelim_stats_graphs'],
            //     icon: props => <DonutLargeOutlinedIcon {...props} />,
            // },
            {
                label: MESSAGES.stats_data_monitoring,
                key: 'data_monitoring',
                permissions: ['iaso_trypelim_stats_graphs'],
                icon: props => <QueryStatsOutlinedIcon {...props} />,
            },
            {
                label: MESSAGES.stats_reports,
                key: 'reports',
                permissions: ['iaso_trypelim_stats_reports'],
                icon: props => <AssessmentOutlinedIcon {...props} />,
            },
        ],
    },
    {
        label: MESSAGES.datas,
        key: 'old-trypelim/datas',
        icon: props => <MenuBookIcon {...props} />,
        subMenu: [
            {
                label: MESSAGES.datas_tests,
                key: 'tests',
                permissions: ['iaso_trypelim_case_cases'],
                icon: props => <VaccinesOutlinedIcon {...props} />,
            },
            {
                label: MESSAGES.datas_register,
                key: 'register/list',
                permissions: ['iaso_trypelim_case_cases'],
                icon: props => <PersonSearchOutlinedIcon {...props} />,
            },
            {
                label: MESSAGES.datas_duplicates,
                key: 'register/duplicates',
                permissions: ['iaso_trypelim_duplicates'],
                icon: props => <FileCopyOutlinedIcon {...props} />,
            },
        ],
    },
    {
        label: MESSAGES.management,
        key: '',
        icon: props => <StorageIcon {...props} />,
        subMenu: [
            {
                label: MESSAGES.management_devices,
                key: 'old-trypelim/management/devices',
                permissions: ['iaso_trypelim_management_devices'],
                icon: props => <TabletAndroidOutlinedIcon {...props} />,
            },
            {
                label: MESSAGES.management_teams,
                key: 'old-trypelim/management/teams',
                permissions: ['iaso_trypelim_management_teams'],
                icon: props => <GroupsOutlinedIcon {...props} />,
            },
            {
                label: MESSAGES.management_villages,
                key: 'old-trypelim/management/villages',
                permissions: ['iaso_trypelim_management_villages'],
                icon: props => <HolidayVillageOutlinedIcon {...props} />,
            },
            {
                label: MESSAGES.management_coordinations,
                key: 'trypelim/management/coordinations',
                permissions: ['iaso_trypelim_management_coordinations'],
                icon: props => <FormatListBulleted {...props} />,
            },
            {
                label: MESSAGES.management_health_structures,
                key: 'trypelim/management/health_structures',
                permissions: ['iaso_trypelim_management_health_structures'],
                icon: props => <LocalHospitalOutlinedIcon {...props} />,
            },
            {
                label: MESSAGES.management_notifications,
                key: 'old-trypelim/management/notifications',
                permissions: ['iaso_trypelim_lab'], // TODO
                icon: props => <EmailOutlinedIcon {...props} />,
            },
        ],
    },
    {
        label: MESSAGES.plannings,
        key: 'old-trypelim/plannings',
        icon: props => <AssignmentRoundedIcon {...props} />,
        subMenu: [
            {
                label: MESSAGES.plannings,
                key: 'list',
                permissions: ['iaso_trypelim_management_plannings'],
                icon: props => <FormatListBulleted {...props} />,
            },
            {
                label: MESSAGES.plannings_workzones,
                key: 'workzones',
                permissions: ['iaso_trypelim_management_workzones'],
                icon: props => <FormatListBulleted {...props} />,
            },
            {
                label: MESSAGES.plannings_macro,
                key: 'macro',
                permissions: ['iaso_trypelim_plannings_macroplanning'],
                icon: props => <FormatListBulleted {...props} />,
            },
            {
                label: MESSAGES.plannings_micro,
                key: 'micro',
                permissions: ['iaso_trypelim_plannings_microplanning'],
                icon: props => <FormatListBulleted {...props} />,
            },
            {
                label: MESSAGES.plannings_routes,
                key: 'routes',
                permissions: ['iaso_trypelim_plannings_routes'],
                icon: props => <RouteOutlinedIcon {...props} />,
            },
        ],
    },
    {
        label: MESSAGES.vector_control,
        key: 'old-trypelim/vector',
        icon: props => <PolylineOutlinedIcon {...props} />,
        subMenu: [
            {
                label: MESSAGES.vector_control_management,
                key: 'map',
                permissions: ['iaso_trypelim_vectorcontrol'],
                icon: props => <FormatListBulleted {...props} />,
            },
            {
                label: MESSAGES.vector_control_sync,
                key: 'sync',
                permissions: ['iaso_trypelim_vectorcontrol'],
                icon: props => <SyncAltOutlinedIcon {...props} />,
            },
            {
                label: MESSAGES.vector_control_upload,
                key: 'upload',
                permissions: ['iaso_trypelim_vectorcontrolupload'],
                icon: props => <FileUploadOutlinedIcon {...props} />,
            },
        ],
    },
    {
        label: MESSAGES.lab,
        key: 'old-trypelim/lab',
        icon: props => <BiotechIcon {...props} />,
        subMenu: [
            {
                label: MESSAGES.lab_upload,
                key: 'upload',
                permissions: ['iaso_trypelim_lab'],
                icon: props => <FileUploadOutlinedIcon {...props} />,
            },
            {
                label: MESSAGES.lab_import_logs,
                key: 'import_logs',
                permissions: ['iaso_trypelim_lab'],
                icon: props => <NotesOutlinedIcon {...props} />,
            },
        ],
    },
    {
        label: MESSAGES.quality_control,
        key: 'old-trypelim/quality-control',
        icon: props => <PolicyIcon {...props} />,
        subMenu: [
            {
                label: MESSAGES.quality_control_control,
                key: 'dashboard',
                permissions: ['iaso_trypelim_qualitycontrol'],
                icon: props => <SupervisorAccountOutlinedIcon {...props} />,
            },
            {
                label: MESSAGES.quality_control_monitoring,
                key: 'monitoring',
                permissions: ['iaso_trypelim_qualitycontrol'],
                icon: props => <ImportantDevicesRoundedIcon {...props} />,
            },
        ],
    },
    {
        label: MESSAGES.new_quality_control,
        key: 'old-trypelim/new-quality-control',
        icon: props => <PolicyIcon {...props} />,
        subMenu: [
            {
                label: MESSAGES.quality_control_control,
                key: 'dashboard',
                // Note: this permission does not exist, making it temporarily
                // only visible for superusers.
                permissions: ['iaso_trypelim_qualitycontrol'],
                icon: props => <SupervisorAccountOutlinedIcon {...props} />,
            },
            {
                label: MESSAGES.quality_control_monitoring,
                key: 'monitoring',
                // Note: this permission does not exist, making it temporarily
                // only visible for superusers.
                permissions: ['iaso_trypelim_qualitycontrol'],
                icon: props => <ImportantDevicesRoundedIcon {...props} />,
            },
        ],
    },
];
